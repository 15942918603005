import axios from 'axios'

import { ga4Events, popUp, sortArrayList } from '../../utils'
import { toast } from 'react-toastify'
import { getCartDetail, updateCartItems } from '../../helper/cart'

export const GetHomeListing = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_LOADING',
      data: true,
    })
    axios
      .get('/api/listings/get-home')
      .then((res) => {
        const listing = res.data
        dispatch({
          type: 'GET_HOME_LISTING_SUCCESS',
          listing,
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        dispatch({
          type: 'GET_HOME_LISTING_FAILED',
        })
      })
  }
}

export const updatestock = (data) => {
  return async (dispatch) => {
    try {
      await axios.post('/api/listings/update-stock-quantity', data)
    } catch (err) { }
  }
}

export const setFilters = (filterQuery) => (dispatch) => {
  dispatch({
    type: 'SET_FILTER',
    filterQuery,
  })
}
export const setPathname = (pathname) => (dispatch) => {
  dispatch({
    type: 'SET_PATHNAME',
    pathname,
  })
}

const getGAEventsForInitialization = ({
  filterQuery = {},
  listings = [],
  totalCount = 0,
}) => {
  if (filterQuery?.type === 'batteries') {
    return {
      action: 'search_by_batteries',
      category: 'Search',
      search_query: filterQuery?.partname || filterQuery.partnumber || '',
      filter_criteria: JSON.stringify(filterQuery),
      result_count: totalCount,
    }
  }

  if (filterQuery?.type === 'tires') {
    return {
      action: 'search_by_tires',
      category: 'Search',
      search_query: filterQuery?.partname || filterQuery.partnumber || '',
      filter_criteria: JSON.stringify(filterQuery),
      result_count: totalCount,
    }
  }

  if (filterQuery?.type === 'image') {
    return {
      action: 'search_parts_by_image',
      category: 'Search',
      image_source: filterQuery?.file_id,
      result_count: totalCount,
    }
  }

  if ((filterQuery?.partname || filterQuery.partnumber) && filterQuery?.isVINSearch) {
    let action = listings?.length
      ? {
        action: 'parts_searched_from_VIN_Success',
        category: 'Listing',
        query: filterQuery?.partname || filterQuery.partnumber,
        platform: 'web',
        make: filterQuery?.make,
        model: filterQuery?.model,
      }
      : {
        action: 'parts_searched_from_VIN_Failure',
        category: 'Listing',
        query: filterQuery?.partname || filterQuery.partnumber,
        platform: 'web',
        message: 'No results found',
      }
    return action
  }

  if (filterQuery?.partname || filterQuery.partnumber) {
    return {
      action: 'search_spare_parts',
      category: 'Search',
      search_query: filterQuery?.partname || filterQuery.partnumber,
      results_count: totalCount,
    }
  }

  return null
}

export const Initialize = (query = {}, count) => {
  const currentUser = JSON.parse(localStorage.getItem('login'))
  const { skusWithType, ...filterQuery } = query

  let skusLists;
  if(filterQuery && filterQuery.skus) {
    skusLists = filterQuery.skus;
    delete filterQuery.skus;
  }

  return (dispatch, getState) => {
    dispatch({
      type: 'SET_LOADING',
      data: true,
      page: 'listing',
    })

    if (!currentUser && count >= 3) {
    } else {
      let emirate =
        currentUser?.role === 'BUYER' ? currentUser.details?.emirate : ''

      let URL = `/api/listings?sellerAccType=${emirate}&visibility=unhidden`

      if (filterQuery && Object.keys(filterQuery).length > 0) {
        const { page } = query
        URL = `${URL}&page=${page || 1}&perPage=${20}`
        Object.keys(filterQuery).map((key) => {
          if (filterQuery[key] !== null && filterQuery[key] !== undefined) {
            if (key === 'category') {
              URL = `${URL}&category=${filterQuery.category._id
                ? filterQuery.category._id
                : filterQuery.category
                }`
            } else if (key === 'subcategory') {
              URL = `${URL}&subcategory=${filterQuery.subcategory._id
                ? filterQuery.subcategory._id
                : filterQuery.subcategory
                }`
            } else if (key === 'subcategory2') {
              URL = `${URL}&subcategory2=${filterQuery.subcategory2._id
                ? filterQuery.subcategory2._id
                : filterQuery.subcategory2
                }`
            } else URL = `${URL}&${key}=${filterQuery[key]}`
          }
        })
      }

      if (filterQuery.getAfterMarket) {
        dispatch({
          type: 'SET_LOADING_AFTER_MARKET',
          data: true,
          page: 'listing',
        })
        const id = toast('Please wait, loading more part')
        axios
          .post(URL, { skus: skusLists }, {params: {skusWithType: JSON.stringify(skusWithType)}})
          // Added getAfterMarket: true for cross reference
          // .post(URL, { skus: skusLists }, {params: {getAfterMarket: true, skusWithType: JSON.stringify(skusWithType)}})
          .then((res) => {
            const { listings, totalCount } = res.data
            let allListings =
              listings?.map((value) => ({
                ...value,
                istecAll: false,
              })) || []

            dispatch({
              type: 'SET_LOADING_AFTER_MARKET',
              data: false,
              page: 'listing',
              searchKey: filterQuery.search,
              listings: allListings ?? [],
              sortBy: filterQuery.sortBy,
            })
            dispatch({
              type: 'SET_LOADING',
              data: false,
              page: 'listing',
            })

          })
          .catch(() => { })
          .finally(() => {
            dispatch({
              type: 'SET_LOADING_AFTER_MARKET',
              data: false,
              page: 'listing',
            })
            toast.dismiss(id)
          })

        //FOR FILTER LISTING
        axios
          .post(`${URL}&listingFilter=true`, { skus: skusLists }, {params: {skusWithType: JSON.stringify(skusWithType)}})
          // Added getAfterMarket: true for cross reference
          // .post(`${URL}&listingFilter=true`, { skus: skusLists }, {params: {getAfterMarket: true, skusWithType: JSON.stringify(skusWithType)}})
          .then((res) => {
            const {
              maxPrice,
              minPrice,
              makeList,
              modelList,
              brandList,
              businessModelList,
              emirateList,
              categoryList,
              subCategoriesList,
            } = res.data

            dispatch({
              type: 'GET_FILTER_AFTERMARKET',
              maxPrice,
              minPrice,
              makeList: sortArrayList({ list: makeList }),
              brandList: sortArrayList({ list: brandList, type: 'string' }),
              businessModelList,
              emirateLists: emirateList,
              categoryList: sortArrayList({ list: categoryList }),
              subCategoriesList: sortArrayList({ list: subCategoriesList }),
              modelList: sortArrayList({ list: modelList }),
            })

            dispatch({
              type: 'SET_LOADING',
              data: false,
              page: 'listing',
            })



          })
          .catch((err) => {
            popUp(err?.response?.data?.message)

            dispatch({
              type: 'SET_LOADING',
              data: false,
              page: 'listing',
            })
          })
      } else {
        dispatch({
          type: 'SET_LOADING',
          data: true,
          page: 'listing',
        })
        //FOR LISTING
        axios
          .post(URL, { skus: skusLists })
          .then((res) => {
            const { listings, totalCount } = res.data

            console.log("HARIOM > listingAction >  INIT > res.data: ", res.data);
            let { brands, make, model, ...query } = filterQuery
            console.log("HARIOM > listingAction >  INIT > filterQuery brands: ", brands);
            console.log("HARIOM > listingAction >  INIT > filterQuery make: ", make);
            let isMakeNotGetData = false;
            if (make && typeof make === 'string') {
              if (totalCount <= 0) {
                isMakeNotGetData = true;
                brands = make;
                make = undefined;
                // axio re-call here for BRANDS if no data in MAKE
                dispatch(Initialize({ ...query, brands, make, model, listingType: 'Genuine' }, 2))
              } else {
                make = [{ _id: make, name: make, disable: true }]
              }
            }

            if (brands && typeof brands === 'string') {
              brands = [{ _id: brands, name: brands, disable: true }]
            }

            if (model && typeof model === 'string') {
              model = [{ _id: model, name: model, disable: true }]
            }

            query = { ...query, brands, make, model }
            let sortingType = ''
            let allListings =
              listings?.map((value) => ({
                ...value,
                istecAll: false,
              })) || []
            let isCallAftermarket = (filterQuery.partname || filterQuery.partnumber)
            if (isCallAftermarket || filterQuery.hasOwnProperty('skus')) {
              let data = { getAfterMarket: true, ...filterQuery }
              if (!filterQuery?.skus?.length && isCallAftermarket) {
                let list = listings?.find(
                  (list) => list.partSKU === isCallAftermarket
                )
                list = list ? [{ pn: list.partSKU, type: list.type }] : []
                data.skusWithType = list
              }
              dispatch(Initialize(data, count))
            }
            if(query.isVINSearch && query.partname) {
              query.make = []
              query.model = []
            }
            if (!isMakeNotGetData) {
              console.log("HARIOM : !isMakeNotGetData", !isMakeNotGetData)
              dispatch({
                type: 'INITIALIZE_SUCCESS',
                searchQuery: query,
                filterQuery, //used for next page api call
                listings: allListings ?? [],
                totalCount: totalCount ?? 0,
              })

              dispatch({
                type: 'SET_LOADING',
                data: false,
                page: 'listing',
              })
            }
            let event = getGAEventsForInitialization({
              filterQuery,
              totalCount,
              listings,
            })

            if (event) {
              ga4Events(event)
            }

            if (!currentUser) {
              dispatch({
                type: 'INCREASE_VISITORS_VISIT_COUNT',
                count: count + 1,
              })
            }
          })
          .catch((err) => {
            dispatch({
              type: 'INITIALIZE_FAILED',
            })
          })

        //FOR FILTER LISTING
        axios
          .post(`${URL}&listingFilter=true`, { skus: skusLists })
          // Added getAfterMarket: true for cross reference
          // .post(`${URL}&listingFilter=true`, { skus: skusLists }, {params: {getAfterMarket: true}})
          .then((res) => {
            const {
              maxPrice,
              minPrice,
              makeList,
              modelList,
              brandList,
              businessModelList,
              emirateList,
              categoryList,
              subCategoriesList,
            } = res.data

            dispatch({
              type: 'GET_FILTER',
              maxPrice,
              minPrice,
              makeList: sortArrayList({ list: makeList }),
              brandList: sortArrayList({ list: brandList, type: 'string' }),
              businessModelList,
              emirateLists: emirateList,
              categoryList: sortArrayList({ list: categoryList }),
              subCategoriesList: sortArrayList({ list: subCategoriesList }),
              modelList: sortArrayList({ list: modelList }),
            })
          })
          .catch((err) => {
            popUp(err?.response?.data?.message)
            dispatch({
              type: 'SET_LOADING',
              data: false,
              page: 'listing',
            })
          })
      }
    }
  }
}

export const visitor = (count) => {
  const currentUser = JSON.parse(localStorage.getItem('login'))

  return (dispatch, getState) => {
    if (!currentUser || currentUser?.status === 'Pending') {
      dispatch({
        type: 'INCREASE_VISITORS_VISIT_COUNT',
        count: count + 1,
      })
    }
  }
}

export const visitor1 = (count) => {
  const currentUser = JSON.parse(localStorage.getItem('login'))

  return (dispatch, getState) => {
    if (!currentUser || currentUser?.status === 'Pending') {
      dispatch({
        type: 'INCREASE_VISITORS_VISIT_COUNT',
        count: count,
      })
    }
  }
}

export const GetMyListings = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_LOADING',
      data: true,
    })
    const login = getState().login
    axios
      .get(`/api/listings/get-my-listing?userId=${login._id}`)
      .then((res) => {
        const { listings } = res.data
        dispatch({
          type: 'GET_MY_LISTING_SUCCESS',
          listings,
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        dispatch({
          type: 'GET_MY_LISTING_FAILED',
        })
      })
  }
}

export const GetMyListingsInPagination = (page, size, filters) => {
  return (dispatch, getState) => {
    const login = getState().login
    dispatch({ type: 'GET_MY_LISTING_BEGIN' })
    let URL = `/api/listings?page=${page}&size=${size}&seller=${login._id}`
    Object.keys(filters).map((key) => {
      if (filters[key] !== undefined && filters[key] !== '') {
        if (key === 'max_price')
          URL = `${URL}&${key}=${filters[key].toString().replace(/\+/g, '%2B')}`
        else URL = `${URL}&${key}=${filters[key]}`
      }
    })
    axios
      .get(URL)
      .then((res) => {
        const listings = res.data
        dispatch({
          type: 'GET_MY_LISTING_SUCCESS',
          listings,
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        dispatch({
          type: 'GET_MY_LISTING_FAILED',
        })
      })
  }
}
export const GetListingsCount = (filters, isInitalRender) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'LOADING_COUNT',
    })
    const login = getState().login
    axios
      .get(`/api/listings/all?count=${true}&user_id=${login._id}`, {
        filters,
      })
      .then((res) => {
        const count = res.data

        dispatch({
          type: 'GET_MY_LISTING_COUNT_SUCCESS',
          count,
          isInitalRender,
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        dispatch({
          type: 'GET_MY_LISTING_COUNT_FAILED',
        })
      })
  }
}

export const AddToCart = (data, eventData) => {
  const currentUser = JSON.parse(localStorage.getItem('login'))
  return (dispatch, getState) => {
    if (currentUser && currentUser.role === 'BUYER') {
      const { isBulkUpload } = getState().list

      if (isBulkUpload) {
        popUp(
          'You cannot add items in your cart while your bulk order is still on process',
          'error'
        )
        return
      }

      if (!currentUser) {
        popUp(
          'Your account is in pending state. Please wait for admin to approves your account and then u can purchase the products'
        )
      } else {
        dispatch({
          type: "SET_IS_ADD_TO_CART_LOADING",
          data: true
        })
        axios
          .get(
            `/api/listings/check-buyability?emirate=${currentUser?.details?.emirate}&list=${data}`
          )
          .then((res) => {
            const { canbuy } = res.data
            if (canbuy) {
              const userCart = updateCartItems(data, "add_single");
              dispatch({
                type: "SET_ITEMS_IN_CART",
                data: userCart?.items ?? []
              })
              dispatch({
                type: "SET_IS_ADD_TO_CART_LOADING",
                data: false
              })
              popUp(
                `A product has been added to the cart. `,
                'View Cart',
                '/checkout'
              )

              if (eventData) {
                ga4Events(eventData)
              }
            } else {
              popUp('This seller do not sell in your emirate.');
              dispatch({
                type: "SET_IS_ADD_TO_CART_LOADING",
                data: false
              })
            }
          })
          .catch((err) => {
            popUp('Cannot add item in the cart. Please try again.');
            dispatch({
              type: "SET_IS_ADD_TO_CART_LOADING",
              data: false
            })
          })
      }
    } else {
      popUp(
        'Sorry! your accout type is seller. Please login as buyer to purchase the products in BuyParts 24'
      )
    }
  }
}

export const UpdateCart = (data, eventData) => {
  return (dispatch, getState) => {
    const userCart = updateCartItems(data, "update_single");

    dispatch({
      type: "SET_ITEMS_IN_CART",
      data: userCart?.items ?? []
    })

    if (eventData) {
      ga4Events(eventData)
    }
  }
}

export const addMultipleItemsInCart = (data, eventData) => {
  return (dispatch) => {
    const userCart = updateCartItems(data, "add_multiple");
    dispatch({
      type: "SET_ITEMS_IN_CART",
      data: userCart?.items ?? []
    })

    if (eventData) {
      ga4Events(eventData)
    }
  }
}

export const clearUserCart = () => {
  return (dispatch) => {
    const currentUser = JSON.parse(localStorage.getItem('login'))

    const cart = getCartDetail(currentUser?._id)

  }
}

export const SetVisibility = (id, visibility) => {
  return (dispatch, getState) => {
    axios
      .put(`/api/listings/${id}`, {
        visibility: !visibility,
      })
      .then((res) => {
        dispatch({
          type: 'SET_VISIBILITY_SUCCESS',
          listing_id: id,
          visibility: !visibility,
        })
        popUp(res.data.message || 'Successfully updated.')
      })
      .catch((err) => {
        popUp(
          err.response.data.message ||
          'There was a problem with server. Please try again.'
        )
        dispatch({
          type: 'SET_VISIBILITY_FAILED',
        })
      })
  }
}

export const handleBulkDelete = (id, action) => {
  return (dispatch, getState) => {
    const currentUser = JSON.parse(localStorage.getItem('login'))
    axios
      .delete(`/api/listings/delete-bulk-products?action=${action}`, {
        data: { id, deletedBy: currentUser._id },
      })
      .then((res) => {
        dispatch({
          type: 'BULK_PRODUCT_DELETE',
        })

        dispatch(GetMyListingsInPagination(1, 18, {}))
        if (res?.data?.message) {
          popUp(res.data.message)
        } else {
          popUp(res.data.message)
        }
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
      })
  }
}
export const GetMyFavorites = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_LOADING',
      data: true,
    })
    const login = getState().login
    axios
      .get(`/api/listings/get-my-favorites?user_id=${login._id}`)
      .then((res) => {
        const { listings } = res.data
        dispatch({
          type: 'GET_MY_FAVORITES_SUCCESS',
          listings,
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        dispatch({
          type: 'GET_MY_FAVORITES_FAILED',
        })
      })
  }
}

export const FilterListing = ({ skusWithType, ...query }, action) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_LOADING',
      data: true,
      page: 'listing',
    })
    const currentUser = JSON.parse(localStorage.getItem('login'))
    let emirate =
      currentUser?.role === 'BUYER' ? currentUser.details?.emirate : ''
    let URL = `/api/listings?sellerAccType=${emirate}&visibility=unhidden`

    let filterOptions = null,
      sortOption = null
    let skusLists;

    if (Object.keys(query).length > 0) {
      const {
        search,
        emirates,
        priceRange,
        category,
        subcategory,
        make,
        model,
        year,
        brands,
        sortBy,
        type,
        businessModel,
        page,
        brandSearch,
        voltage,
        amp,
        batteryYear,
        width,
        height,
        rimSize,
        tireYear,
        categoryLists,
        subCategoryLists,
        listingType,
        file_id,
        isVINSearch,
        getAfterMarket,
        partname,
        partnumber,
        filterFromSidebar
      } = query
      if (query && query.skus) {
        skusLists = query.skus;
        delete query.skus;
      }
      URL = `${URL}&page=${page || 1}&perPage=${20}`
      if (search && !brandSearch) URL = `${URL}&search=${search}`
      if (brandSearch) URL = `${URL}&brandSearch=${brandSearch}`
      if (emirates && emirates.length > 0) {
        let options = []
        emirates.map((emirate) => options.push(emirate.name))
        URL = `${URL}&emirates=${options}`
        filterOptions = { ...filterOptions, emirates: options }
      }
      if (priceRange) URL = `${URL}&priceRange=${priceRange}`
      if (category) {
        URL = `${URL}&category=${category?._id}`
        filterOptions = { ...filterOptions, category: category?._id }
      }
      if (subcategory) {
        URL = `${URL}&subcategory=${subcategory?._id}`
        filterOptions = { ...filterOptions, subcategory: subcategory?._id }
      }
      if (make) {
        let options = []
        make.map((m) => options.push(m.name ?? m))
        URL = `${URL}&make=${options}`
        filterOptions = { ...filterOptions, make: options }
      }
      if (model) {
        let options = []
        model.map((m) => options.push(m.name ?? m))
        URL = `${URL}&model=${options}`
        filterOptions = { ...filterOptions, model: options }
      }
      if (brands && brands?.length) {
        let options = []
        brands.map((m) => options.push(m?.name ?? m))
        URL = `${URL}&brands=${options}`
        filterOptions = { ...filterOptions, brands: options }
      }
      if (categoryLists && categoryLists?.length) {
        let options = []
        categoryLists.map((m) => options.push(m?._id ?? m))
        URL = `${URL}&category=${options}`
        filterOptions = { ...filterOptions, category: options }
      }
      if (subCategoryLists && subCategoryLists?.length) {
        let options = []
        subCategoryLists.map((m) => options.push(m?._id ?? m))
        URL = `${URL}&subcategory=${options}`
        filterOptions = { ...filterOptions, subcategory: options }
      }
      if (year) {
        URL = `${URL}&year=${year}`
        filterOptions = { ...filterOptions, year: year }
      }
      if (sortBy && Object.keys(sortBy).length > 0) {
        let sort = ''
        Object.keys(sortBy).map((key) => {
          if (sortBy[key] === 1) sort += key
          else sort += `-${key}`
        })
        URL = `${URL}&sortBy=${sort}`
        sortOption = sort
      }
      if (type) {
        URL = `${URL}&type=${type}`
        filterOptions = { ...filterOptions, type: type }
      }
      if (businessModel) {
        let options = []
        businessModel.map((bM) => options.push(bM._id))
        URL = `${URL}&businessModel=${options}`
        filterOptions = { ...filterOptions, businessModel: options }
      }
      // if (skus) {
      //   let options = []
      //   skus.map((bM) => options.push(bM))
      //   URL = `${URL}&skus=${options}`
      //   filterOptions = { ...filterOptions, skus: options }
      // }
      if (voltage) {
        URL = `${URL}&voltage=${voltage}`
        filterOptions = { ...filterOptions, voltage }
      }
      if (amp) {
        URL = `${URL}&amp=${amp}`
        filterOptions = { ...filterOptions, amp }
      }
      if (batteryYear) {
        URL = `${URL}&batteryYear=${batteryYear}`
        filterOptions = { ...filterOptions, batteryYear }
      }
      if (width) {
        URL = `${URL}&width=${width}`
        filterOptions = { ...filterOptions, width }
      }
      if (height) {
        URL = `${URL}&height=${height}`
        filterOptions = { ...filterOptions, height }
      }
      if (rimSize) {
        URL = `${URL}&rimSize=${rimSize}`
        filterOptions = { ...filterOptions, rimSize }
      }
      if (tireYear) {
        URL = `${URL}&tireYear=${tireYear}`
        filterOptions = { ...filterOptions, tireYear }
      }
      if (listingType) {
        URL = `${URL}&listingType=${listingType}`
        filterOptions = { ...filterOptions, listingType }
      }
      if (file_id) {
        URL = `${URL}&file_id=${file_id}`
        filterOptions = { ...filterOptions, file_id }
      }
      if (isVINSearch) {
        URL = `${URL}&isVINSearch=${isVINSearch}`
        //  URL = `${URL}&isVINSearch=false`
        filterOptions = { ...filterOptions, isVINSearch }
      }
      if (getAfterMarket) {
        URL = `${URL}&getAfterMarket=${getAfterMarket}`
      }
      if (partname) {
        URL = `${URL}&partname=${partname}`
      }
      if (partnumber) {
        URL = `${URL}&partnumber=${partnumber}`
      }
      if (filterFromSidebar) {
        URL = `${URL}&filterFromSidebar=${true}`
      }
    }

    if (query.getAfterMarket) {
      dispatch({
        type: 'SET_LOADING_AFTER_MARKET',
        data: true,
        page: 'listing',
      })
      axios
        .post(URL, {
          skus: skusLists
        }, {params: {skusWithType: JSON.stringify(skusWithType)}})
        // Added getAfterMarket: true for cross reference
        // }, {params: {getAfterMarket: true, skusWithType: JSON.stringify(skusWithType)}})
        .then((res) => {
          const { listings, totalCount } = res.data
          let allListings =
            listings?.map((value) => ({
              ...value,
              istecAll: false,
            })) || []

          dispatch({
            type: 'SET_LOADING_AFTER_MARKET',
            data: false,
            page: 'listing',
            listings: allListings ?? [],
            sortBy: query.sortBy,
          })
          dispatch({
            type: 'SET_LOADING',
            data: false,
            page: 'listing',
          })
        })
        .catch(() => { })
        .finally(() => {
          dispatch({
            type: 'SET_LOADING_AFTER_MARKET',
            data: false,
            page: 'listing',
          })
        })
    } else {
      dispatch({
        type: 'SET_LOADING',
        data: true,
        page: 'listing',
      })
      const requestBody = {};
      const paramData = {};
      if(skusLists) {
        requestBody.skus = skusLists;
        // Added getAfterMarket: true for cross reference
        // paramData.getAfterMarket = true;

      }
      axios
        .post(URL, requestBody, {params: paramData})
        .then((res) => {
          const { listings, totalCount } = res.data
          dispatch({
            type: 'FILTER_LISTING_SUCCESS',
            listings,
            searchKey: query.partname || query.partnumber,
            totalCount,
            sortBy: query.sortBy,
          })

          dispatch({
            type: 'SET_LOADING',
            data: false,
            page: 'listing',
          })
          let searchedKey = query.partname || query.partnumber
          if (searchedKey || query.hasOwnProperty('skus')) {
            let data = { getAfterMarket: true, ...query }
            if (!query?.skus?.length && searchedKey) {
              let skusWithType = listings?.find(
                (list) => list.partSKU === searchedKey
              )
              data.skusWithType = skusWithType
                ? [{ pn: skusWithType.partSKU, type: skusWithType.type }]
                : []
            }
            dispatch(FilterListing(data, action))
          }

          if (action === 'sort' && sortOption) {
            ga4Events({
              action: 'plp_sort',
              category: 'Listing',
              sort_criteria: sortOption,
            })
          }

          if (action !== 'pagination' && action !== 'sort' && filterOptions) {
            ga4Events({
              action: 'plp_filter',
              category: 'Listing',
              filter_criteria: JSON.stringify(filterOptions),
            })
          }
        })
        .catch((err) => {
          popUp('There was a problem with server. Please try again.')
          dispatch({
            type: 'FILTER_LISTING_FAILED',
          })
        })
    }
  }
}

export const GetAllListing = () => {
  return (dispatch, getState) => {
    axios
      .get('/api/listings/get-all')
      .then((res) => {
        const listing = res.data
        dispatch({
          type: 'GET_ALL_LISTING_SUCCESS',
          listing,
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        dispatch({
          type: 'GET_ALL_LISTING_FAILED',
        })
      })
  }
}

export const SetFavouriteListing = (e, listing_id) => {
  e.preventDefault()
  return (dispatch, getState) => {
    const login = getState().login
    if (!login) return
    axios
      .post('/api/listings/set-favourite', {
        listing_id: listing_id,
        user_id: login._id,
      })
      .then((res) => {
        dispatch({
          type: 'SET_FAVOURITE_LISTING_SUCCESS',
          data: res.data,
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
      })
  }
}

export const getBannerList = () => {
  return (dispatch, getState) => {
    const login = getState().login
    let url = `/api/banner?from=fe`

    if (login && login?.role === 'BUYER') {
      url += `&userId=${login?._id}`
    }

    axios
      .get(url)
      .then((res) => {
        dispatch({
          type: 'GET_BANNER_LIST',
          data: res.data?.banner,
        })
      })
      .catch((_) => {
      })
  }
}

export const SetLoading = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_LOADING',
      data,
    })
  }
}

export const SetSimilarListing = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_SIMILAR_LISTING',
      data,
    })
    return Promise.resolve()
  }
}

export const SetSearchQuery = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_SEARCH_QUERY',
      data,
    })
    return Promise.resolve()
  }
}

export const SetListings = (data) => {
  return (dispatch, getState) => {
    let newBrandList = data?.brandList?.map((b) => {
      return { name: b, _id: b }
    })
    let newData = {
      ...data,
      brandList: newBrandList || [],
    }
    dispatch({
      type: 'SET_LISTINGS',
      data: newData,
    })
  }
}

export const SetTradesoftSKUs = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_TRADESOFT_SKUS',
      data,
    })
    return Promise.resolve()
  }
}

export const getSameNameItem = (name, type, seller) => async (dispatch) => {
  try {
    const currentUser = JSON.parse(localStorage.getItem('login'))
    let emirate =
      currentUser?.role === 'BUYER' ? currentUser.details?.emirate : ''
    let res = await axios.get(
      `/api/listings/get-by-same-name?partName=${name}&type=${type}&seller=${seller}&emirate=${emirate}`
    )
    dispatch({ type: 'SAME_NAME_ITEM_LIST', data: res.data })
  } catch (err) {
    popUp('There was a problem with server. Please try again.')
  }
}

export const VinSearchDetails = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_SPAREPART_SEARCHDETAILS',
      data,
    })
    return Promise.resolve()
  }
}

export const updateBulkCart = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_BULK_UPLOAD_LIST',
      data,
    })
    return Promise.resolve()
  }
}

export const getBulkCartListing = () => {
  return (dispatch, getState) => {
    const { bulkUploadedList } = getState().list
    const { details } = getState().login
    dispatch({
      type: 'GET_BULK_CART_LIST_BEGIN',
    })

    const result = Object.values(
      bulkUploadedList.reduce(
        (acc, obj) => ({ ...acc, [obj?.partSKU]: obj }),
        {}
      )
    )
    axios
      .post('/api/listings/bulk-cart-listings', {
        listings: result,
        emirate: details?.emirate,
      })
      .then((res) => {
        dispatch({
          type: 'GET_BULK_CART_LIST_SUCCESS',
          data: res.data,
        })

        //pop up error message incase there is no any skus
        if (
          res?.data?.products &&
          res?.data?.products?.length === 0 &&
          res?.data?.unAvailableProducts &&
          res?.data?.unAvailableProducts?.length === 0
        ) {
          popUp("Cannot find any SKU's from uploaded file.")
        }

        //to get total order items

        const totalOrders =
          res.data?.products &&
          res.data?.products?.length !== 0 &&
          res.data?.products?.reduce((a, b) => {
            a = a + b?.orderQty
            return a
          }, 0)

        dispatch({
          type: 'SET_BULK_CART_LIST_COUNT',
          data: totalOrders,
        })
      })
      .catch((err) => {
        dispatch({
          type: 'GET_BULK_CART_LIST_ERROR',
        })
        popUp('There was a problem with server. Please try again.', 'error')
      })
  }
}

export const updateBulkCartListing = (data, type) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_BULK_CART',
      data,
    })
    let list = getState().list
    let totalCount = 0

    switch (type) {
      case 'increment':
        totalCount = list?.bulkCartListCount + 1
        break
      case 'decrement':
        totalCount = list?.bulkCartListCount - 1
        break
      default:
        totalCount =
          data && data?.length !== 0
            ? data.reduce((a, b) => {
              a = a + b?.orderQty
              return a
            }, 0)
            : 0
    }

    dispatch({
      type: 'SET_BULK_CART_LIST_COUNT',
      data: totalCount,
    })
    return Promise.resolve()
  }
}

export const getUserVinSearchHistory = (searchTerm) => {
  return (dispatch, getState) => {
    const login = getState().login
    if (!login) return
    dispatch({
      type: 'GET_VIN_HISTORY_BEGIN',
    })

    axios
      .get(
        `/api/info/get-user-vin-history?user=${login?._id}&vin=${searchTerm}`
      )
      .then((res) => {
        dispatch({
          type: 'GET_VIN_HISTORY_SUCCESS',
          data: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: 'GET_VIN_HISTORY_ERROR',
        })
      })
  }
}

export const getCollectionListing = ({
  query,
  action,
  isFirstRender = false,
}) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      data: true,
      page: 'collection',
    })
    const currentUser = JSON.parse(localStorage.getItem('login'))
    let emirate =
      currentUser?.role === 'BUYER' ? currentUser.details?.emirate : ''
    let URL = ``

    let filterOptions = null,
      sortOption = null

    if (Object.keys(query).length > 0) {
      const {
        slug,
        emirates,
        priceRange,
        categoryId,
        make,
        model,
        brands,
        sortBy,
        businessModel,
        page,
        categoryLists,
      } = query
      URL = `/api/collections/${slug}?sellerAccType=${emirate}&visibility=unhidden&page=${page || 1
        }&perPage=${20}`

      if (emirates && emirates.length > 0) {
        let options = []
        emirates.map((emirate) => options.push(emirate.name))
        URL = `${URL}&emirates=${options}`
        filterOptions = { ...filterOptions, emirates: options }
      }

      if (priceRange) {
        URL = `${URL}&priceRange=${priceRange}`
        filterOptions = { ...filterOptions, priceRange }
      }

      if (categoryId) {
        URL = `${URL}&category=${categoryId}`
        filterOptions = { ...filterOptions, category: categoryId }
      }

      if (categoryLists && categoryLists?.length) {
        let options = []
        categoryLists.map((m) => options.push(m._id))
        URL = `${URL}&category=${options}`
        filterOptions = { ...filterOptions, category: options }
      }

      if (make && make?.length) {
        let options = []
        make.map((m) => options.push(m._id ?? m))
        URL = `${URL}&make=${options}`
        filterOptions = { ...filterOptions, make: options }
      }

      if (model && model?.length) {
        let options = []
        model.map((m) => options.push(m._id ?? m))
        URL = `${URL}&model=${options}`
        filterOptions = { ...filterOptions, model: options }
      }

      if (brands && brands?.length) {
        let options = []
        brands.map((m) => options.push(m.name))
        URL = `${URL}&brands=${options}`
        filterOptions = { ...filterOptions, brands: options }
      }

      if (sortBy && Object.keys(sortBy).length > 0) {
        let sort = ''
        Object.keys(sortBy).map((key) => {
          if (sortBy[key] === 1) sort += key
          else sort += `-${key}`
        })
        URL = `${URL}&sortBy=${sort}`
        sortOption = sort
      }
      if (businessModel) {
        let options = []
        businessModel.map((bM) => options.push(bM._id))
        URL = `${URL}&businessModel=${options}`
        filterOptions = { ...filterOptions, business_model: options }
      }
    }

    axios
      .get(URL)
      .then((res) => {
        const { listings, totalCount, ...rest } = res?.data?.collection
        dispatch({
          type: 'COLLECTION_LISTING_SUCCESS',
          listings: listings,
          collection: rest,
          totalCount,
        })

        if (action === 'sort' && sortOption) {
          ga4Events({
            action: 'collection_plp_sort',
            category: 'Listing',
            sort_criteria: sortOption,
          })
        }

        if (action !== 'pagination' && action !== 'sort' && filterOptions) {
          ga4Events({
            action: 'collection_plp_filter',
            category: 'Listing',
            filter_criteria: JSON.stringify(filterOptions),
          })
        }
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        dispatch({
          type: 'FILTER_LISTING_FAILED',
        })
      })

    if (isFirstRender) {
      axios.get(`${URL}&listingFilter=true`).then((res) => {
        const {
          maxPrice,
          minPrice,
          makeList,
          modelList,
          brandList,
          businessModelList,
          emirateList,
          categoryList,
        } = res.data
        dispatch({
          type: 'GET_FILTER',
          maxPrice,
          minPrice,
          makeList: sortArrayList({ list: makeList }),
          brandList: sortArrayList({ list: brandList, type: 'string' }),
          businessModelList,
          emirateLists: emirateList,
          modelList: sortArrayList({ list: modelList }),
          categoryList: sortArrayList({ list: categoryList }),
        })
      })
    }
  }
}

//FOR LOCAL CART

export const setUserCart = (userId) => {
  return (dispatch) => {
    try {
      const { userCart } = getCartDetail(userId)
      dispatch({
        type: "SET_ITEMS_IN_CART",
        data: userCart?.items ?? []
      });
    } catch (error) {
      localStorage.setItem("cart", JSON.stringify([]));
      dispatch({
        type: "SET_ITEMS_IN_CART",
        data: []
      });
    }
  }
}
